import React, { useState, useCallback } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";

import "../custom.css";
import ButtonWrapper from "./ButtonWrapper";
import { useAuth, useProfile } from "../auth/authContext";
import { StripeMetadataItem, useApi } from "../api/shareparkApi";

const Details = () => {
  return (
    <Container className="px-5">
      <Row lg={2} md={1} className="g-0 mt-5">
        <Col className="px-2 mb-4">
          <PaymentDetails />
        </Col>
        <Col className="px-2">
          <PersonalDetails />
        </Col>
      </Row>
    </Container>
  );
};

const PaymentDetails = () => {
  const { token } = useAuth();
  const profile = useProfile();
  const { spUrl } = useApi();

  return (
    <Container>
      <Row>
        <Col>
          <h2>Your Payment Details</h2>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          {(!profile.parker.paymentDetails ||
            profile.parker.paymentDetails?.length) === 0 ? (
            <div className="text-danger">No payment details</div>
          ) : (
            <ul className="list-group">
              {profile.parker.paymentDetails?.map(
                (item: StripeMetadataItem, index: number) => {
                  return (
                    <li className="list-group-item" key={index}>
                      <span>{item.Card.Brand}</span>
                      <span className="ms-3">{item.Card.Last4}</span>
                      <span className="ms-3">
                        {item.Card.ExpMonth}/{item.Card.ExpYear}
                      </span>
                      {index === 0 && (
                        <span
                          className={`ms-3 badge ${
                            profile.parker.hasExpiredPayment
                              ? "bg-danger"
                              : "bg-secondary"
                          }`}
                          style={{ float: "right" }}
                        >
                          {profile.parker.hasExpiredPayment
                            ? "Expired"
                            : "Default"}
                        </span>
                      )}
                    </li>
                  );
                }
              )}
            </ul>
          )}

          <form
            id="stripe"
            action={`https://${spUrl}/payments/create-session/${profile.parker.id}`}
            method="POST"
          >
            <input type="hidden" name="token" value={token ? token : ""} />
            <button className="orangeButton px-4 mt-3" type="submit">
              Add Payment Details
            </button>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

const PersonalDetails = () => {
  const profile = useProfile();
  const { editDetails } = useApi();

  const [editMode, setEditMode] = useState(false);
  const [parkerDetails, setParker] = useState(profile.parker);

  React.useEffect(() => {
    setParker(profile.parker);
  }, [profile.parker]);

  var dummyStreetTypes = [
    "",
    "Alley",
    "Arcade",
    "Avenue",
    "Boulevard",
    "Bypass",
    "Circuit",
    "Close",
    "Corner",
    "Court",
    "Crescent",
    "Cul-de-sac",
    "Drive",
    "Esplanade",
    "Green",
    "Grove",
    "Highway",
    "Junction",
    "Lane",
    "Link",
    "Mews",
    "Parade",
    "Place",
    "Ridge",
    "Road",
    "Square",
    "Street",
    "Terrace",
  ];

  var states = ["", "ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"];

  const handleChange = useCallback(
    // structure map to support React.ChangeEvent<HTMLInputElement> & React.ChangeEvent<HTMLSelectElement>
    (e: { target: { name: string; value: string } }) => {
      setParker({
        ...parkerDetails,
        [e.target.name]: e.target.value,
      });
    },
    [parkerDetails, setParker]
  );
  const handleTermsChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setParker({
        ...parkerDetails,
        agreeTermsConditions: e.target.checked ? new Date() : null,
      });
    },
    [parkerDetails, setParker]
  );

  const onSaveDetails = () => {
    editDetails(parkerDetails);
    setEditMode(false);
  };

  const handleChangePostcode = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (
        value.toString().match(/^[0-9]{1,4}$/) != null ||
        value.toString() === ""
      ) {
        setParker({
          ...parkerDetails,
          postcode: e.target.value,
        });
      }
    },
    [parkerDetails, setParker]
  );

  const handleNotificationTypeChange = (value: "email" | "sms") => () => {
    setParker({
      ...parkerDetails,
      notificationType: value,
    });
  };

  return (
    <Container>
      <Row>
        <Col>
          <h2>Your Personal Details</h2>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <ButtonWrapper
            disabled={editMode}
            buttonText="Edit Personal Details"
            fullWidth={true}
            onClick={() => setEditMode(true)}
          />
        </Col>
      </Row>

      {profile.validationErrors.length > 0 && (
        <div className="mt-3">
          <ul>
            {profile.validationErrors.map((item, index) => {
              return (
                <li key={index} className="text-danger">
                  {item}
                </li>
              );
            })}
          </ul>
        </div>
      )}

      <Row className="mt-4 formStyle">
        <Col>
          <Form>
            <Row md={2} sm={1}>
              <Form.Group as={Col} controlId="personalDetailsFirstName">
                <Form.Label>First Name *</Form.Label>
                <Form.Control
                  disabled={!editMode}
                  name="firstName"
                  value={parkerDetails.firstName ?? ""}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="personalDetailsLastName">
                <Form.Label>Last Name *</Form.Label>
                <Form.Control
                  disabled={!editMode}
                  name="lastName"
                  value={parkerDetails.lastName ?? ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Row md={2} sm={1}>
              <Form.Group as={Col} controlId="personalDetailsMobile">
                <Form.Label>Mobile *</Form.Label>
                <Form.Control
                  disabled={!editMode}
                  name="mobile"
                  value={parkerDetails.mobile ?? ""}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="personalDetailsEmail">
                <Form.Label>Email *</Form.Label>
                <Form.Control
                  disabled={true}
                  type="email"
                  name="email"
                  defaultValue={parkerDetails.email ?? ""}
                />
              </Form.Group>
            </Row>
            <Row md={2} sm={1}>
              <Form.Group as={Col} controlId="personalDetailsStreetNumber">
                <Form.Label>Street Number</Form.Label>
                <Form.Control
                  disabled={!editMode}
                  name="streetNumber"
                  value={parkerDetails.streetNumber ?? ""}
                  onChange={handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="personalDetailsStreetName">
                <Form.Label>Street Name</Form.Label>
                <Form.Control
                  disabled={!editMode}
                  name="streetName"
                  value={parkerDetails.streetName ?? ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Row md={2} sm={1}>
              <Form.Group as={Col} controlId="personalDetailsStreetType">
                <Form.Label>Street Type</Form.Label>
                <Form.Select
                  disabled={!editMode}
                  className="select-style"
                  name="streetType"
                  value={parkerDetails.streetType ?? ""}
                  onChange={handleChange}
                >
                  {dummyStreetTypes.map((type, i) => (
                    <option key={i} value={type}>
                      {type}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} controlId="personalDetailsStreetSuburb">
                <Form.Label>Suburb</Form.Label>
                <Form.Control
                  disabled={!editMode}
                  name="suburb"
                  value={parkerDetails.suburb ?? ""}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
            <Row md={2} sm={1}>
              <Form.Group as={Col} controlId="personalDetailsState">
                <Form.Label>State</Form.Label>
                <Form.Select
                  disabled={!editMode}
                  className="select-style"
                  name="state"
                  value={parkerDetails.state ?? ""}
                  onChange={handleChange}
                >
                  {states.map((state, i) => (
                    <option key={i} value={state}>
                      {state}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} controlId="personalDetailsPostcode">
                <Form.Label>postcode</Form.Label>
                <Form.Control
                  disabled={!editMode}
                  name="postcode"
                  value={parkerDetails.postcode ?? ""}
                  onChange={handleChangePostcode}
                  placeholder="----"
                />
              </Form.Group>
            </Row>
            <Row md={2} sm={1}>
              <Form.Group as={Col} controlId="personalNotification">
                <Form.Label>Notification Type *</Form.Label>
                <br />
                <Form.Check
                  disabled={!editMode}
                  className="smallGreyFont"
                  inline
                  label="Email"
                  type="radio"
                  name="notificationType"
                  id="email"
                  checked={parkerDetails.notificationType === "email"}
                  onChange={handleNotificationTypeChange("email")}
                />
                <Form.Check
                  disabled={!editMode}
                  className="smallGreyFont"
                  inline
                  label="SMS"
                  type="radio"
                  name="notificationType"
                  id="sms"
                  checked={parkerDetails.notificationType === "sms"}
                  onChange={handleNotificationTypeChange("sms")}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="personalDetailsTerms">
                <Form.Check
                  disabled={!editMode || !!profile.parker.agreeTermsConditions}
                  inline
                  type="checkbox"
                  id="terms"
                  name="terms"
                  checked={parkerDetails.agreeTermsConditions !== null}
                  onChange={handleTermsChange}
                />
                <br />
                <Form.Label className="text-wrap fw-normal smallGreyFont">
                  I agree to the Terms and Conditions
                </Form.Label>
              </Form.Group>
            </Row>
            {editMode && (
              <Row className="mt-2">
                <Col md={6}>
                  <ButtonWrapper
                    buttonText="Save Changes"
                    fullWidth={true}
                    onClick={() => onSaveDetails()}
                  />
                </Col>
                <Col md={3}>
                  <button
                    onClick={() => setEditMode(false)}
                    className="greyButton"
                  >
                    Cancel
                  </button>
                </Col>
              </Row>
            )}
          </Form>
        </Col>
      </Row>
    </Container>
  );
};
export default Details;
